import { Elements as StripeElements } from "@stripe/react-stripe-js";
import { StripeElementLocale, loadStripe } from "@stripe/stripe-js";
import { ReactNode, useMemo, useState } from "react";
import { useRouter } from "next/router";
import StripeContext, { StripeContextValue } from "./StripeContext";

type Props = {
  children: ReactNode;
  pageIncludesPaymentForm: boolean;
  currentClientSecret?: string;
};

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY ?? ""
);

const StripeProvider = ({
  children,
  pageIncludesPaymentForm,
  currentClientSecret,
}: Props) => {
  const { locale } = useRouter();
  const [clientSecret, setClientSecret] = useState<string | undefined>(
    currentClientSecret
  );

  const contextState = useMemo<StripeContextValue>(
    () => ({
      clientSecret,
      setClientSecret,
      stripeElementsReady: !pageIncludesPaymentForm || !!clientSecret,
    }),
    [clientSecret, pageIncludesPaymentForm]
  );

  return (
    <StripeContext.Provider value={contextState}>
      {clientSecret ? (
        <StripeElements
          stripe={stripePromise}
          options={{
            clientSecret: contextState.clientSecret,
            locale: (locale as StripeElementLocale) ?? "en",
          }}
        >
          {children}
        </StripeElements>
      ) : (
        <StripeElements stripe={stripePromise}>{children}</StripeElements>
      )}
    </StripeContext.Provider>
  );
};

export default StripeProvider;
