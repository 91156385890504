import { TFunction, useTranslation } from "next-i18next";
import { useState } from "react";
import WhereIsMyVinImage from "@public/images/where-is-my-vin.png";
import classNames from "classnames";
import { TranslationKeys } from "src/@types/i18next";
import Tooltip from "../../Tooltip";
import LocalImage from "../../LocalImage";
import Modal from "../../Modal";
import Button from "../../Button";

const whereIsMyVinGraphic = (t: TFunction, scaleToToolTip?: boolean) => {
  const listClassName = `list-disc ${
    scaleToToolTip ? "list-outside" : "list-inside"
  }`;

  return (
    <div>
      <LocalImage
        src={WhereIsMyVinImage}
        alt={t("where_is_my_vin_graphic", { ns: "common" })}
        width="480"
        height="370"
        priority
      />

      <div
        className={classNames("text-start text-themed-text-3", {
          "p-2 text-xs": scaleToToolTip,
          "px-1 sm:px-2 md:px-7 text-xs sm:text-sm": !scaleToToolTip,
        })}
      >
        <p
          className={classNames("font-themed-weight-1 pb-2", {
            "text-sm": scaleToToolTip,
            "text-sm sm:text-base lg:text-lg": !scaleToToolTip,
          })}
        >
          {t("you_can_find_your_vin", { ns: "common" })}
        </p>
        <div className="flex px-1">
          <div>
            <p className="font-themed-weight-1 pb-1">
              {t("within_the_vehicle", { ns: "common" })}:
            </p>
            <ul className={listClassName}>
              <li>{t("drivers_side_dashboard", { ns: "common" })}</li>
              <li>{t("inside_drivers_door", { ns: "common" })}</li>
            </ul>
          </div>
          <div className="ml-auto">
            <p className="font-themed-weight-1 pb-1">
              {t("in_documents", { ns: "common" })}:
            </p>
            <ul className={listClassName}>
              <li>{t("ownership_document", { ns: "common" })}</li>
              <li>{t("insurance_policy", { ns: "common" })}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

type Props = {
  themeClassNames?: string;
  labelKey?: TranslationKeys["common"];
};

const WhereIsMyVinGraphic = ({
  themeClassNames,
  labelKey = "common:where_is_my_vin",
}: Props) => {
  const { t } = useTranslation(["common"]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div id="whereIsMyVin" className="focus:outline-non inline-block">
        <div className="justify-start flex text-themed-text-2 font-themed-weight-3 hover:cursor-pointer">
          <div
            onClick={() => setIsModalOpen(true)}
            onKeyDown={() => setIsModalOpen(true)}
            role="button"
            tabIndex={0}
            className="md:hidden"
          >
            <p className="underline">{t(labelKey)}</p>
          </div>
          <div className="hidden md:block">
            <Tooltip
              content={whereIsMyVinGraphic(t, true)}
              toolTipStyle="light"
              arrowPlacement="top-left"
              displayOn="hover"
            >
              <p className="underline">{t(labelKey)}</p>
            </Tooltip>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        title={t("common:where_is_my_vin")}
        alignment="center"
        onClose={() => setIsModalOpen(false)}
        themeClassNames={themeClassNames}
      >
        {whereIsMyVinGraphic(t)}
        <div className="flex flex-col w-[30%] mx-auto pt-6">
          <Button
            spacing="tight-hug"
            size="small"
            onClick={() => setIsModalOpen(false)}
          >
            {t("common:got_it")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default WhereIsMyVinGraphic;
