import { formatCurrency } from "@common/helpers/numberFormatting";
import { Trans, useTranslation } from "next-i18next";
import React, { ReactNode } from "react";
import { TranslationKeys } from "src/@types/i18next";
import { ProductCoverage } from "../types/ExtendedWarrantyRates";

type SubHeadingParams = {
  years: number;
  months: number;
  kms: string | null;
  yearString: string;
  termExpiryDate?: string;
};

type CoverageConfig = Record<
  string,
  {
    type: (params: { index: number }) => string;
    termDescription: (
      params: SubHeadingParams,
      isDisplayedOnCards?: boolean
    ) => string | null;
    details: ReactNode[];
    caption: (params: object) => string;
  }
>;

const useCoverageConfig = (
  coverages?: ProductCoverage[],
  locale?: string
): CoverageConfig => {
  const { t } = useTranslation(["purchase", "common"]);

  const hasSU = coverages?.some((c) => c.type === "SU");
  const hasPR = coverages?.some((c) => c.type === "PR");

  const createTransElement = (
    key: TranslationKeys["purchase"],
    components?: Record<string, React.ReactElement>
  ) => React.createElement(Trans, { i18nKey: key, components, t });

  let classicDetails: ReactNode[];

  if (hasSU && !hasPR) {
    classicDetails = [
      createTransElement("purchase:everything_in_basic_plus", {
        bold: React.createElement("span", { className: "font-semibold" }),
      }),
      createTransElement("purchase:classic_coverage_details_2"),
    ];
  } else {
    classicDetails = [
      createTransElement("purchase:everything_in_basic_plus", {
        bold: React.createElement("span", { className: "font-semibold" }),
      }),
      createTransElement("purchase:everything_in_preferred_plus", {
        bold: React.createElement("span", { className: "font-semibold" }),
      }),
      createTransElement("purchase:classic_coverage_details_2"),
    ];
  }

  return {
    BA: {
      type: () => t("common:ew_coverage_type_basic"),
      termDescription: (params, isDisplayedOnCards) => {
        if (params.termExpiryDate) {
          return isDisplayedOnCards
            ? t("purchase:date_or_kms", params)
            : t("purchase:expires_date_or_kms", params);
        }

        if (params.years < 1 && params.months > 0) {
          return t("purchase:months_or_kms", params);
        }
        return t("purchase:years_or_kms", params);
      },
      details: [createTransElement("purchase:basic_coverage_details")],
      caption: (params) => t("purchase:coverage_quote_info", params),
    },
    PR: {
      type: () => t("common:ew_coverage_type_preferred"),
      termDescription: (params, isDisplayedOnCards) => {
        if (params.termExpiryDate) {
          return isDisplayedOnCards
            ? t("purchase:date_or_kms", params)
            : t("purchase:expires_date_or_kms", params);
        }

        if (params.years < 1 && params.months > 0) {
          return t("purchase:months_or_kms", params);
        }
        return t("purchase:years_or_kms", params);
      },
      details: [
        createTransElement("purchase:everything_in_basic_plus", {
          bold: React.createElement("span", { className: "font-semibold" }),
        }),
        createTransElement("purchase:preferred_coverage_details_2"),
      ],
      caption: (params) => t("purchase:coverage_quote_info", params),
    },
    CL: {
      type: () => t("common:ew_coverage_type_classic"),
      termDescription: (params, isDisplayedOnCards) => {
        if (params.termExpiryDate) {
          return isDisplayedOnCards
            ? t("purchase:date_or_kms", params)
            : t("purchase:expires_date_or_kms", params);
        }

        if (params.years < 1 && params.months > 0) {
          return t("purchase:months_or_kms", params);
        }
        return t("purchase:years_or_kms", params);
      },
      details: classicDetails,
      caption: (params) => t("purchase:coverage_quote_info", params),
    },
    SU: {
      type: () => t("common:ew_coverage_type_superior"),
      termDescription: (params, isDisplayedOnCards) => {
        if (params.termExpiryDate) {
          return isDisplayedOnCards
            ? t("purchase:date_or_kms", params)
            : t("purchase:expires_date_or_kms", params);
        }

        if (params.years < 1 && params.months > 0) {
          return t("purchase:months_or_kms", params);
        }
        return t("purchase:years_or_kms", params);
      },
      details: [
        createTransElement("purchase:everything_in_basic_plus", {
          bold: React.createElement("span", { className: "font-semibold" }),
        }),
        createTransElement("purchase:everything_in_classic_plus", {
          bold: React.createElement("span", { className: "font-semibold" }),
        }),
        createTransElement("purchase:superior_coverage_details_3"),
      ],
      caption: (params) => t("purchase:coverage_quote_info", params),
    },
    SW: {
      type: (params) => {
        const translations = [
          t("common:ew_coverage_type_superior_wrap"),
          t("common:ew_coverage_type_superior_wrap"),
          t("common:ew_coverage_type_superior_wrap"),
        ];
        return translations[params.index - 1];
      },
      termDescription: (params, isDisplayedOnCards) => {
        if (params.termExpiryDate) {
          return isDisplayedOnCards
            ? t("purchase:date_or_kms", params)
            : t("purchase:expires_date_or_kms", params);
        }
        if (params.years < 1 && params.months > 0) {
          return t("purchase:months_or_kms", params);
        }
        return t("purchase:years_or_kms", params);
      },
      details: [],
      caption: (params) => t("purchase:wrap_quote_info", params),
    },
    LI: {
      type: (params) => {
        const translations = [
          t("common:ew_coverage_type_essential"),
          t("common:ew_coverage_type_essential"),
          t("common:ew_coverage_type_essential"),
        ];
        return translations[params.index - 1];
      },
      termDescription: (params) => {
        if (params.years < 1 && params.months > 0) {
          return t("purchase:months_or_kms", params);
        }
        return t("purchase:years_or_kms", params);
      },
      details: [],
      caption: (params) =>
        t("purchase:ew_ama_quote_info", {
          ...params,
          serviceClaimAmount: formatCurrency(2500, locale ?? "en", {
            showCents: "never",
          }),
          totalClaimAmount: formatCurrency(5000, locale ?? "en", {
            showCents: "never",
          }),
        }),
    },
    MA: {
      type: (params) => {
        const translations = [
          t("common:ew_coverage_type_ama"),
          t("common:ew_coverage_type_ama"),
          t("common:ew_coverage_type_ama"),
        ];
        return translations[params.index - 1];
      },
      termDescription: (params) =>
        t("purchase:ew_ama_coverage_years_amount", params),
      details: [],
      caption: (params) =>
        t("purchase:ew_ama_quote_info", {
          ...params,
          serviceClaimAmount: formatCurrency(5000, locale ?? "en", {
            showCents: "never",
          }),
          totalClaimAmount: formatCurrency(10000, locale ?? "en", {
            showCents: "never",
          }),
        }),
    },
    AB: {
      type: (params) => {
        const translations = [
          t("common:ew_coverage_type_ama"),
          t("common:ew_coverage_type_ama"),
          t("common:ew_coverage_type_ama"),
        ];
        return translations[params.index - 1];
      },
      termDescription: (params) => {
        if (params.years < 1 && params.months > 0) {
          return t("purchase:months_or_kms", params);
        }
        return t("purchase:years_or_kms", params);
      },
      details: [],
      caption: (params) =>
        t("purchase:ew_ama_quote_info", {
          ...params,
          serviceClaimAmount: formatCurrency(2500, locale ?? "en", {
            showCents: "never",
          }),
          totalClaimAmount: formatCurrency(5000, locale ?? "en", {
            showCents: "never",
          }),
        }),
    },
  };
};

export default useCoverageConfig;
