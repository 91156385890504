import { Instant, LocalDate } from "@js-joda/core";
import { Translation } from "@common/types/Translation";
import { TranslationKeys } from "src/@types/i18next";
import { PurchaseTradeIn } from "./PurchaseTradeIn";
import { LenderSubmission } from "./LenderSubmission";

export type DealPurchaseDetailsStep = {
  id: number;
  order: number;
  nameTranslation: TranslationKeys["common"];
};

export const DealPurchaseDetailsVehicleStep: DealPurchaseDetailsStep = {
  id: 1,
  order: 2,
  nameTranslation: "common:vehicle_details",
};
export const DealPurchaseDetailsQuickAccountStep: DealPurchaseDetailsStep = {
  id: 2,
  order: 3,
  nameTranslation: "common:account_setup",
};
export const DealPurchaseDetailsCustomerInformationStep: DealPurchaseDetailsStep =
  {
    id: 3,
    order: 1,
    nameTranslation: "common:customer_information",
  };
export const DealPurchaseDetailsCompleteStep: DealPurchaseDetailsStep = {
  id: 4,
  order: 4,
  nameTranslation: "common:complete",
};

export const DealPurchaseDetailsSteps: DealPurchaseDetailsStep[] = [
  DealPurchaseDetailsVehicleStep,
  DealPurchaseDetailsQuickAccountStep,
  DealPurchaseDetailsCustomerInformationStep,
  DealPurchaseDetailsCompleteStep,
];

export type Vehicle = {
  vin: string;
  odometerCount: number; // TODO this is also nullable
  purchaseDate: string | null;
  purchasePrice?: number | null;
  vehiclePrice?: number | null;
  availableTrims: string[];
  trim?: string;
  year?: number;
  make?: string;
  model?: string;
  stockNumber?: string;
  image?: string;
  exteriorColour: string | null;
  chromeDecodedData: {
    year: number;
    make: string;
    model: string;
    trims: string[];
  } | null;
  availableExteriorColours: string[];
  bodyStyle?: string;
} | null;

export type Tax = {
  name: string;
  amount: number;
  rate: number;
};

export type PurchasePricing = {
  products: {
    name: string;
    price: number;
  }[];
  taxes: Tax[];
  subTotal: number;
  discount: number;
  totalPrice: number;
  amountFinanced: number | null;
  cashDownAmount: number | null;
  paymentFrequencyId: number | null;
  term: number | null;
  payment: number;
};

export type PurchaseTodo = {
  dealTodoId: string;
  todoId: number;
  name: Translation;
  todoTypeId: number;
  completedAt: string | null;
};

type DealSourceInfo = {
  adId?: string | null;
  userId?: string | null;
  sessionId?: string | null;
  appId?: string | null;
  locationId?: string | null;
  ctaId?: string | null;
};

export type PurchaseProductConfiguration = Record<
  string,
  string | number | LocalDate | undefined
>;

export type ProductAddOn = {
  code: string;
};

export type ProductDocument = {
  id: string;
  contractNumber: string;
};

export type IncludedAddOn = {
  code: string;
  name: string;
};

export interface DealDocument {
  id: string;
  name: string;
  documentTypeId: number;
  scanResultId: number;
}

export interface Address {
  addressTypeId: number | null;
  addressNumber: string;
  street: string;
  suiteNumber: string;
  fullAddress: string;
  postalCode: string;
  streetTypeId: number | null;
  city: string;
  provinceId: number | null;
  provinceAbbr: string;
  startLivingDate: string | null;
  latitude: number | null;
  longitude: number | null;
}

export interface BankInformation {
  financialInstitutionName: string | null;
  maskedBankAccountNumber: string | null;
  lastPayoutConfigurationAttemptedAt: string | null;
}

export interface Mortgage {
  homeTypeId: number | null;
  marketValue: number | null;
  mortgageAmount: number | null;
  monthlyPayment: number | null;
  mortgageHolder: string;
  mortgageAdditionalInfo: string;
}

export interface Employment {
  employer: string;
  employerPhoneNumber: string;
  occupation: string;
  typeId: number;
  startDate: string;
  statusId: number;
  city: string;
  provinceId: number;
  addressTypeId: number | null;
  addressNumber: string;
  street: string;
  suiteNumber: string;
  postalCode: string;
  streetTypeId: number | null;
}

export interface Income {
  grossIncome: number | null;
  grossIncomeBasisId: number | null;
  otherIncomeTypeId: number | null;
  otherIncome: number | null;
  otherIncomeBasisId: number | null;
  otherIncomeDescription: string | null;
}

export type PurchaseCustomer = {
  userId: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  phone: string;
  mobilePhone: string;
  dateOfBirth: string | null;
  salutationId: number;
  suffixId: number | null;
  sexId: number | null;
  maritalStatusId: number | null;
  agreedVehicleDetailsTermsAndConditionsAt: Instant | null;
  agreedCustomerDetailsTermsAndConditionsAt: Instant | null;
  typeId: number | null;
  payoutMethodVerified: boolean;
  payoutMethodCodeSet: boolean;
  currentAddress: Address | null;
  previousAddress: Address | null;
  mortgage: Mortgage | null;
  income: Income | null;
  currentEmployment: Employment | null;
  previousEmployment: Employment | null;
  bankInformation: BankInformation | null;
};

export type PurchaseDropOffDetails = {
  dropOffLocationId: string | null;
  dropOffLocationAppointmentAt: string | null;
  dropOffLocationName: string | null;
};

export type PurchaseDocuments = {
  anyDocumentIsSigned: boolean;
  anyDocumentRequiresCreate: boolean;
  documentsAreSigned: boolean;
  eSigningStarted: boolean;
  dealDocuments: DealDocument[] | null;
  activeDealProductDocuments: ProductDocument[];
};

export type PromoCodeApplied = {
  id: string;
  name: string;
  discountAmount: number;
  expiryDate: LocalDate | null;
};

export type Purchase = {
  id: string;
  exists?: boolean;
  currentStepId: number | null;
  currentDetailsStepId: number | null;
  vehicleOwnershipStatusId: number | null;
  approximateBuyingTimeframeId: number | null;
  dealTypeId: number;
  dealStatusId: number | null;
  dealSegmentId: number | null;
  dealSource: string;
  createdAt: string;
  vehicle: Vehicle | null;
  purchaseTradeIn: PurchaseTradeIn | null;
  tradeIns: PurchaseTradeIn[];
  customers: PurchaseCustomer[];
  documentSummary: PurchaseDocuments;
  todos: PurchaseTodo[];
  receiptUrl: string | null;
  overwriteCandidate: {
    id: string;
    currentStepId: number | null;
    vehicle?: Vehicle;
  };
  lenderSubmission: LenderSubmission | null;
  sourceInfo: DealSourceInfo | null;
  pricing: PurchasePricing;
  products: {
    id: string;
    name: string;
    discount: number;
    config: PurchaseProductConfiguration;
    addOns: ProductAddOn[];
  }[];
  dropOffDetails: PurchaseDropOffDetails | null;
  appliedPromoCode?: PromoCodeApplied | null;
};

export type PurchaseNotFoundResponse = {
  success: boolean;
  exists: boolean;
};

export type PurchasePreview = {
  product: {
    config: PurchaseProductConfiguration;
  };
  pricing: PurchasePricing;
};

export type StartPurchaseRequest = {
  vehicleStockNumber?: string | null;
  vin?: string | null;
  year?: number | null;
  make?: string | null;
  model?: string | null;
  trim?: string | null;
  coverImage?: string | null;
  mileage?: number | null;
  purchaseDate?: LocalDate | null;
  purchasePrice?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  address?: string | null;
  city?: string | null;
  province?: string | null;
  postalCode?: string | null;
  sourceAdId?: string | null;
  sourceUserId?: string | null;
  sourceSessionId?: string | null;
  sourceAppId?: string | null;
  sourceLocationId?: string | null;
  sourceCtaId?: string | null;
  utmSource?: string | null;
  utmMedium?: string | null;
  utmCampaign?: string | null;
  utmContent?: string | null;
  source: string;
  locale?: string;
  abandonExistingDeals?: boolean;
  fuelType?: string | null;
};

export function tryGetActiveAftermarketPurchase(
  consumerFinancing: Purchase | PurchaseNotFoundResponse | undefined
): Purchase | undefined {
  const activePurchase = consumerFinancing as Purchase;

  return activePurchase && activePurchase.id ? activePurchase : undefined;
}

export function tryGetVehicleDescription(
  year?: number | null,
  make?: string | null,
  model?: string | null
): string {
  if (!year || !make || !model) {
    return "";
  }
  return `${year} ${make} ${model}`;
}
