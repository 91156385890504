import { Controller, FieldValues } from "react-hook-form";
import { ControlledFormInputProps } from "@common/types/FormInputProps";
import { useTranslation } from "next-i18next";
import DatePicker from "../DatePicker";

type Props<T extends FieldValues> = {
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
} & ControlledFormInputProps<T>;

const WarrantyStartDatePicker = <TFormValues extends FieldValues>({
  errors,
  rules,
  minDate,
  maxDate,
  required = false,
  ...props
}: Props<TFormValues>): JSX.Element => {
  const { t } = useTranslation(["common", "admin"]);
  const error = props.name ? errors?.[props.name] : undefined;

  return (
    <Controller
      rules={{
        required: {
          value: required,
          message: t("common:required"),
        },
        ...rules,
      }}
      render={({ field: { onChange, value } }) => (
        <DatePicker
          feedback={error?.message?.toString()}
          status={error ? "error" : "default"}
          label={t("admin:warranty_start_date")}
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
          selected={value}
        />
      )}
      {...props}
    />
  );
};

export default WarrantyStartDatePicker;
