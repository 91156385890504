import Form from "@common/components/form";
import { useTranslation } from "next-i18next";
import vehicleValidation from "@common/helpers/validations/vehicleValidation";
import { FieldValues } from "react-hook-form";
import { useUppercaseAlphaNumericMask } from "@common/hooks/useMask";
import { FormInputProps } from "@common/types/FormInputProps";
import WhereIsMyVinGraphic from "./WhereIsMyVinGraphic";

type Props<T extends FieldValues> = {
  themeClassNames?: string;
  showWhereIsMyVin?: boolean;
  labelOption?: "short" | "long";
  required?: boolean;
} & Omit<FormInputProps<T>, "label">;

const VinInput = <TFormValues extends FieldValues>({
  name,
  register,
  errors,
  className,
  themeClassNames,
  showWhereIsMyVin,
  labelOption,
  required = false,
  ...props
}: Props<TFormValues>): JSX.Element => {
  const { t } = useTranslation(["common"]);

  const {
    unMaskPipe: unMaskUppercaseAlphaNumericPipe,
    maskPipe: maskUppercaseAlphaNumericPipe,
  } = useUppercaseAlphaNumericMask();

  const error = name ? errors?.[name] : undefined;

  return (
    <div className={className}>
      <Form.Input
        id="vin"
        label={labelOption === "short" ? t("common:vin") : t("common:vin_long")}
        autoComplete="on"
        feedback={error?.message?.toString()}
        status={error ? "error" : "default"}
        maxLength={vehicleValidation.vin.maxLength}
        minLength={vehicleValidation.vin.maxLength}
        {...(register &&
          name &&
          register(name, {
            required: {
              value: required,
              message: t("common:required"),
            },
            maxLength: {
              value: vehicleValidation.vin.maxLength,
              message: t("common:invalid_vin"),
            },
            validate: {
              invalidVin: (value) => {
                if (!value) return true; // allow required to handle this
                return (
                  vehicleValidation.vin.validVin(value as string) ||
                  t("common:invalid_vin")
                );
              },
            },
            onChange: (e) => {
              const unmasked = unMaskUppercaseAlphaNumericPipe(e.target.value);
              const masked = maskUppercaseAlphaNumericPipe(unmasked);
              e.target.value = masked;
            },
          }))}
        {...props}
      />
      {showWhereIsMyVin && (
        <WhereIsMyVinGraphic themeClassNames={themeClassNames} />
      )}
    </div>
  );
};

export default VinInput;
