import { createContext, Dispatch, SetStateAction } from "react";

export type StripeContextValue = {
  clientSecret: string | undefined;
  setClientSecret: Dispatch<SetStateAction<string | undefined>>;
  stripeElementsReady: boolean;
};

const StripeContext = createContext<StripeContextValue>({
  stripeElementsReady: false,
} as StripeContextValue);

export default StripeContext;
