import classNames from "classnames";
import { ReactNode } from "react";
import LoadingSpinner, { Props as LoadingSpinnerProps } from "./LoadingSpinner";

type Props = {
  content?: ReactNode | string;
  children: ReactNode;
  isLoading: boolean;
} & Pick<LoadingSpinnerProps, "iconColor">;

const OverlayLoader = ({ children, content, isLoading, iconColor }: Props) => {
  return (
    <div className="relative">
      <div
        className={classNames(
          "flex text-center bg-white bg-opacity-75 h-full w-full top-0 left-0 absolute z-[38]",
          { invisible: !isLoading }
        )}
      >
        <div className="m-auto text-gray-600">
          {content || (
            <LoadingSpinner isLoading isOverlay={false} iconColor={iconColor} />
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

export default OverlayLoader;
