import { NameId } from "@common/types/NameId";
import { TFunction } from "next-i18next";

enum DealStatusEnum {
  InProgress = 1,
  NeedsReview = 2,
  Purchased = 3,
  Cancelled = 4,
  Abandoned = 5,
  PayoutApproved = 6,
}

export function getDealStatuses(t: TFunction): NameId[] {
  return [
    {
      name: t("purchased", { ns: "admin" }),
      id: DealStatusEnum.Purchased.toString(),
    },
    {
      name: t("in_progress", { ns: "common" }),
      id: DealStatusEnum.InProgress.toString(),
    },
    {
      name: t("cancelled", { ns: "admin" }),
      id: DealStatusEnum.Cancelled.toString(),
    },
    {
      name: t("needs_review", { ns: "admin" }),
      id: DealStatusEnum.NeedsReview.toString(),
    },
  ].sort((a, b) => a.name.localeCompare(b.name));
}

export default DealStatusEnum;
