import Form from "@common/components/form";
import { useTranslation } from "next-i18next";
import vehicleValidation from "@common/helpers/validations/vehicleValidation";
import { FieldValues } from "react-hook-form";
import { FormInputProps } from "@common/types/FormInputProps";

type Props<T extends FieldValues> = {
  required?: boolean;
} & FormInputProps<T>;

const MakeInput = <TFormValues extends FieldValues>({
  name,
  register,
  errors,
  required = false,
  ...props
}: Props<TFormValues>): JSX.Element => {
  const { t } = useTranslation(["common"]);

  const error = name ? errors?.[name] : undefined;

  return (
    <Form.Input
      id="make"
      label={t("common:make")}
      feedback={error?.message?.toString()}
      status={error ? "error" : "default"}
      maxLength={vehicleValidation.make.maxLength}
      {...(register &&
        name &&
        register(name, {
          required: {
            value: required,
            message: t("common:required"),
          },
          maxLength: {
            value: vehicleValidation.make.maxLength,
            message: t("common:invalid_make"),
          },
        }))}
      {...props}
    />
  );
};

export default MakeInput;
