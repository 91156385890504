import api from "@modules/api/api";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { FeatureFlag, FeatureFlags } from "../types/FeatureFlag";
import featureFlagsQueryKeys from "../featureFlagQueryKeys";

export const getFeatureFlagList = (): Promise<FeatureFlag[]> => {
  return api.get(`api/feature-flags`);
};

export const prefetchFeatureFlagList = async (queryClient: QueryClient) => {
  await queryClient.prefetchQuery({
    queryKey: featureFlagsQueryKeys.featureFlagList(),
    queryFn: () => getFeatureFlagList(),
  });
};

export default function useFeatureFlags() {
  const { data, error, isLoading } = useQuery<FeatureFlag[], Error>({
    queryKey: featureFlagsQueryKeys.featureFlagList(),
    queryFn: () => getFeatureFlagList(),
    staleTime: 5 * 60 * 1000,
  });

  const isEnabled = (featureFlagName: FeatureFlags) => {
    const featureFlag = data?.find((f) => f.name === featureFlagName);
    return featureFlag?.enabled ?? false;
  };

  return { data, isEnabled, error, isLoading };
}
