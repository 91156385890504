import { Combobox as HeadlessCombobox, Transition } from "@headlessui/react";
import { Fragment, ReactNode, Ref, useMemo, useState } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";
import { Translation } from "@common/types/Translation";
import translate from "@common/helpers/translate";
import { CheckIcon, ChevronDownIcon, CloseIcon } from "../icons";
import { Status } from "./types";
import Hint from "./Hint";
import Feedback from "./Feedback";

interface Item {
  name: string | Translation;
  id: string | number;
}

type Props<T> = {
  items: T[];
  /**
   * Unique key `Option` prop
   */
  keyOption: (item: T) => string | number;

  /**
   * Option's display value
   */
  displayOption: (item?: T) => string | number | boolean | ReactNode;

  /**
   * Option's selected value
   */
  valueOption: (item: T) => T | string | number | boolean;

  /**
   * Option's disable prop
   */
  disabledOption?: (item: T) => boolean;

  /**
   * Custom render for Option
   */
  renderOption?: (
    item: T,
    selected?: boolean,
    active?: boolean,
    disabled?: boolean
  ) => JSX.Element;

  label?: string;
  placeholder?: string;
  hint?: string;
  name?: string;
  disabled?: boolean;
  leftIcon?: ReactNode;
  dropdownIcon?: ReactNode;
  status?: Status;
  feedback?: ReactNode;
  hideDropdownBtn?: boolean;
  onInputChange?: (value: string) => void;
  onInputClick?: () => void;
  itemsFilter?: "nameIncludesQuery" | "none";
  onItemsFilter?: (item: T, query: string) => boolean;
  itemsTextDisplay?: "truncate" | "full";
  inputRef?: Ref<HTMLInputElement>;
  inputTextSize?: "sm" | "md";
  showClearButton?: boolean;
  onClear?: () => void;
  openOnClick?: boolean;
  groupBy?: keyof T;
};

type MultiComboboxProps<T> = {
  multiple: true;
  onChange: (selected: T[]) => void;
  selectedValue?: T[];
  displayButton: (items: T[]) => string | number | boolean;
} & Props<T>;

type ComboboxProps<T> = {
  multiple?: false;
  onChange: (selected: T) => void;
  selectedValue?: T;
  displayButton: (items: T) => string | number | boolean;
} & Props<T>;

const ComboboxDropdown = <T,>({
  open,
  items,
  keyOption,
  displayOption,
  valueOption,
  disabledOption,
  renderOption,
  label,
  placeholder,
  disabled = false,
  leftIcon,
  dropdownIcon,
  status = "default",
  onInputChange,
  onInputClick,
  hideDropdownBtn = false,
  itemsFilter = "nameIncludesQuery",
  onItemsFilter,
  itemsTextDisplay = "truncate",
  inputRef,
  inputTextSize = "md",
  showClearButton = false,
  displayValue,
  onClear,
  hint,
  openOnClick,
  groupBy,
}: Omit<Props<T>, "name" | "feedback"> & {
  open: boolean;
  displayValue: () => string;
}) => {
  const [query, setQuery] = useState("");
  const router = useRouter();
  const locale = router?.locale || "";
  const filteredItems: T[] | Item[] = useMemo(() => {
    if (onItemsFilter) {
      return items?.filter((item) => onItemsFilter(item as T, query));
    }
    const itemsDefault = items as unknown as Array<Item>;
    return itemsFilter === "none" || query === ""
      ? items
      : itemsDefault?.filter((item) => {
          const searchTerm = query
            .split(",")
            .pop()
            ?.trim()
            .toLowerCase()
            .replace(/\s+/g, "");
          if (typeof item.name === "string") {
            return item.name
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(searchTerm || "");
          }
          if (locale && (item.name as Translation)) {
            return translate(item?.name as Translation, locale)
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(searchTerm || "");
          }
          return "";
        });
  }, [items, onItemsFilter, query, itemsFilter, locale]);

  const statusColor: Record<string, string> = {
    default:
      "border-other-light-100 focus:border-primary-bold focus:shadow-primary-bold/8",
    error: "border-error focus:border-error focus:shadow-error/8",
  };

  const inputClassName = classNames(
    "body-4 form-input bg-white border rounded-lg w-full focus:outline-none focus:ring-0 focus:shadow-input placeholder-gray-300",
    {
      "bg-background-light-300 disabled:border-other-light-100": disabled,
    },
    {
      "pl-8": leftIcon,
    },
    {
      "pr-8": dropdownIcon || showClearButton,
    },
    {
      "text-xs sm:text-sm h-10 font-medium": inputTextSize === "sm",
    },
    statusColor[status] || statusColor.default
  );

  const defaultRenderOption = (item: T, selected?: boolean) => (
    <>
      <span
        className={classNames("block", {
          "font-medium": selected,
          "font-normal": !selected,
          truncate: itemsTextDisplay === "truncate",
          "pl-3": groupBy,
        })}
      >
        {displayOption(item)}
      </span>
      {selected ? (
        <span
          className={classNames(
            selected ? "text-white" : "text-primary-bold",
            "absolute inset-y-0 right-0 flex items-center pr-4"
          )}
        >
          <CheckIcon
            className="h-3 w-3 text-tertiary-apple-600"
            aria-hidden="true"
          />
        </span>
      ) : null}
    </>
  );

  const comboboxOption = (item: T) => (
    <HeadlessCombobox.Option
      key={keyOption(item)}
      // eslint-disable-next-line @typescript-eslint/no-shadow
      className={({ active, disabled }) =>
        classNames(
          "cursor-default select-none relative py-2 px-3",
          {
            "bg-background-light-300 text-gray-900": active,
          },
          {
            "text-gray-900": !active,
          },
          {
            "text-gray-100": disabled,
          }
        )
      }
      disabled={disabledOption ? disabledOption(item) : false}
      value={valueOption(item)}
    >
      {({ selected }) =>
        renderOption
          ? renderOption(item, selected)
          : defaultRenderOption(item, selected)
      }
    </HeadlessCombobox.Option>
  );

  const comboboxInput = () => (
    <HeadlessCombobox.Input
      className={inputClassName}
      onChange={(event) => {
        setQuery(event.target.value);
        if (onInputChange) {
          onInputChange(event.target.value);
        }
      }}
      onClick={() => onInputClick?.()}
      displayValue={displayValue}
      placeholder={placeholder}
      ref={inputRef}
    />
  );

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="flex-initial text-text-light-100 caption-1">
          <HeadlessCombobox.Label className="uppercase flex-initial basis-1/2 text-gray-500 caption-1">
            {label}
          </HeadlessCombobox.Label>
        </div>
      </div>
      <div className="relative py-1">
        <span className="absolute inset-y-2 left-0 flex items-center pl-2 h-8 w-8 text-text-light-200 pointer-events-none">
          {leftIcon}
        </span>
        {openOnClick ? (
          <HeadlessCombobox.Button className="w-full">
            {comboboxInput()}
          </HeadlessCombobox.Button>
        ) : (
          comboboxInput()
        )}
        {!hideDropdownBtn && (
          <HeadlessCombobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-5 focus:outline-none">
            <span className="absolute inset-y-2 right-0 flex items-center h-8 w-8 text-text-light-200 pointer-events-none">
              {dropdownIcon || <ChevronDownIcon />}
            </span>
          </HeadlessCombobox.Button>
        )}
        {/* For now, we don't support showing dropdown button AND clear button at the same time */}
        {hideDropdownBtn && showClearButton && (
          <button
            type="button"
            className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-5 focus:outline-none"
            onClick={() => {
              setQuery("");
              onInputChange?.("");
              onClear?.();
            }}
          >
            <span className="absolute inset-y-2 -right-1 flex items-center h-8 w-8 text-text-light-200 pointer-events-none">
              <CloseIcon />
            </span>
          </button>
        )}
        <Transition
          show={open}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <HeadlessCombobox.Options className="absolute z-10 mt-1 w-full bg-white shadow-elevation-03 max-h-60 rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            {filteredItems.map((item, i) => {
              const currentItem = item as T;
              if (groupBy && groupBy in (item as Record<string, unknown>)) {
                const currentGroupLabel = currentItem[groupBy];
                const showGroupLabel =
                  i === 0 ||
                  currentGroupLabel !== (filteredItems[i - 1] as T)[groupBy];

                return (
                  <div
                    key={keyOption(currentItem)}
                    className={classNames({
                      "border-t border-gray-50 pt-3": showGroupLabel && i !== 0,
                      "pt-2": i === 0,
                    })}
                  >
                    {showGroupLabel && (
                      <span className="caption-1 pl-3">
                        {`${currentGroupLabel}`}
                      </span>
                    )}
                    {comboboxOption(currentItem)}
                  </div>
                );
              }
              return comboboxOption(currentItem);
            })}
          </HeadlessCombobox.Options>
        </Transition>
        <div>{hint && <Hint hint={hint} align="left" />}</div>
      </div>
    </>
  );
};

const Combobox = <T,>({
  onChange,
  items,
  selectedValue,
  keyOption,
  displayOption,
  displayButton,
  valueOption,
  disabledOption,
  renderOption,
  label,
  placeholder,
  hint,
  name = "",
  disabled = false,
  leftIcon,
  dropdownIcon,
  status = "default",
  feedback = "",
  onInputChange,
  onInputClick,
  hideDropdownBtn = false,
  itemsFilter = "nameIncludesQuery",
  onItemsFilter,
  itemsTextDisplay = "truncate",
  inputRef,
  inputTextSize = "md",
  showClearButton = false,
  onClear,
  multiple,
  openOnClick,
  groupBy,
}: MultiComboboxProps<T> | ComboboxProps<T>) => {
  const commonProps = {
    items,
    keyOption,
    displayOption,
    valueOption,
    disabledOption,
    renderOption,
    label,
    placeholder,
    hint,
    disabled,
    leftIcon,
    dropdownIcon,
    status,
    onInputChange,
    onInputClick,
    hideDropdownBtn,
    itemsFilter,
    onItemsFilter,
    itemsTextDisplay,
    inputRef,
    inputTextSize,
    showClearButton,
    onClear,
    openOnClick,
    groupBy,
  };

  return (
    <div>
      {multiple ? (
        <HeadlessCombobox
          value={selectedValue}
          onChange={onChange}
          multiple
          disabled={disabled}
          name={name}
        >
          {({ open }) => (
            <ComboboxDropdown
              open={open}
              displayValue={() => {
                return displayButton(selectedValue as T[]) as string;
              }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...commonProps}
            />
          )}
        </HeadlessCombobox>
      ) : (
        <HeadlessCombobox
          value={selectedValue}
          onChange={onChange}
          disabled={disabled}
          name={name}
        >
          {({ open }) => (
            <ComboboxDropdown
              open={open}
              displayValue={() => {
                return displayButton(selectedValue as T) as string;
              }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...commonProps}
            />
          )}
        </HeadlessCombobox>
      )}

      {feedback && <Feedback status={status}>{feedback}</Feedback>}
    </div>
  );
};

export default Combobox;
