import { LocalDate } from "@js-joda/core";
import { TFunction } from "next-i18next";

const maxYear = LocalDate.now().year() + 1;

const vin = {
  maxLength: 17,
  validVin: (value: string) => value.length === 17,
};

const year = {
  maxLength: 4,
  validYear: (value: number | null) => !!value && value >= 1900,
  beforeCurrentYear: (value: number | null) => !!value && value <= maxYear,
};

const make = {
  maxLength: 50,
};

const model = {
  maxLength: 50,
};

const trim = {
  maxLength: 50,
};

const bodyType = {
  required: true,
  maxLength: 50,
};

const exteriorColour = {
  required: true,
  maxLength: 25,
};

const fuelType = {
  required: true,
  maxLength: 50,
};

// max length is the length of the input content that users see, which includes commas
// value length is what saved to form state
const odometer = {
  required: true,
  maxLength: 7, // has 1 comma
  valueLength: 6,
  valid: (value: number | null) => {
    if (!value) return false;
    return value?.toString().length <= 6;
  },
  notZero: (value: number, t: TFunction) => {
    if (+value === 0) {
      return t("odometer_cannot_be_zero", { ns: "purchase" });
    }
    return true;
  },
};

const price = {
  required: true,
  minLength: 1,
  maxLength: 11, // has 2 commas
  valueLength: 9,
};

const warrantyVehiclePurchasePrice = {
  required: true,
  minLength: 1,
  maxLength: 7, // has 1 comma
  valueLength: 6,
};

const lienHolder = {
  maxLength: 50,
};

const suggestedSalePrice = {
  minLength: 1,
  maxLength: 11, // has 2 commas
  valueLength: 9,
};

const vehicleValidation = {
  vin,
  year,
  make,
  model,
  trim,
  bodyType,
  exteriorColour,
  fuelType,
  odometer,
  price,
  warrantyVehiclePurchasePrice,
  lienHolder,
  suggestedSalePrice,
};

export default vehicleValidation;
